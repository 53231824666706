export const teamColours: { [x: string]: string | undefined } = {
  ART: "#313737",
  CAM: "#dcad4a",
  DAM: "#52d1d8",
  ARD: "#cfaeb7",
  PRE: "#ad0d28",
  UNI: "#e5eb6b",
  CAR: "#585cbc",
  MPM: "#d25c74",
  SAU: "#cccccc",
  TRI: "#262068",
}
