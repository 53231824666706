import styled from "styled-components"
import { getRule } from "../../util/viewports"
import { TableItem } from "../Layout/Table"

export const RaceColumnWrapper = styled(TableItem)<{ mobile?: boolean }>`
  @media ${getRule("min", "laptop")} {
    display: ${p => (p.mobile === null || !p.mobile ? `table-cell` : `none`)};
  }

  @media ${getRule("max", "laptop")} {
    display: ${p => (p.mobile ? `flex` : `none`)};
    justify-content: space-between;
    border-bottom: none;
    padding: 4px 6px;
  }
`

export const Driver = styled.div`
  text-align: left;
  font-weight: 500;
`

export const ColumnDriver = styled(Driver)`
  position: relative;

  @media ${getRule("max", "laptop")} {
    margin-bottom: 4px;
  }
`
