export const countries = {
  ARE: "United Arab Emirates",
  AUT: "Austria",
  AZE: "Azerbaijan",
  BEL: "Belgium",
  BHR: "Bahrain",
  BRA: "Brazil",
  CAN: "Canada",
  CHE: "Switzerland",
  CHN: "China",
  COL: "Colombia",
  CZE: "Czech Republic",
  ESP: "Spain",
  FRA: "France",
  GBR: "Great Britain",
  GER: "Germany",
  HUN: "Hungary",
  IDN: "Indonesia",
  IND: "India",
  ITA: "Italy",
  JPN: "Japan",
  MCO: "Monaco",
  NLD: "Netherlands",
  RUS: "Russia",
  USA: "United States of America",
  empty: "",
}
